.side-menu {
  position: fixed;
  width: 300px;
  height: 100vh;
  background-color: #f1f1f1;
  transition: left 0.3s ease-in-out;
  z-index: 100;
}

.toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  padding: 10px;
  display: none;
}

.menu-content {
  padding: 20px;
}

.menu-content a {
  display: block;
  padding: 10px 20px;
  margin: 0 -20px;
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.menu-content a:hover {
  color: rgb(21, 94, 117);
}

/* Media Query for small screens */
@media (max-width: 767px) {
  .side-menu {
    left: -300px;
    padding-top: 40px;
  }

  .side-menu.open {
    left: 0; /* Slide the menu into view when open */
  }
  .toggle-button {
    display: block;
  }
}

.menu-user-line {
  margin: 25px -20px 0 -20px;
  padding: 5px 10px;
  color: #666666;
  border-top: 1px solid #999999;
  font-size: 0.8em;
}
