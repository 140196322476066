#dashboard-container {
  min-height: 100%;
}

#dashboard-content {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 50px;
  padding-bottom: 200px;
  min-height: 100%;
}

/* Media Query for small screens */
@media (min-width: 767px) {
  #dashboard-content {
    padding-left: 320px;
    padding-top: 30px;
  }
}
